import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";

const Puntaje = ({ s }) => {
	const images = useStaticQuery(graphql`
		query {
			contact: file(relativePath: { eq: "contact.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
			boardPost2: file(relativePath: { eq: "board-post-02.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="review">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Puntaje" />
					<main className="page lightgray-bg-01">
						{/* le puse la class hidden para ocultar */}
						<article className="box-review ">
							<div className="wrapper box">
								<h3 className="prequel-font white-text">CUENTANOS COMO FUE TU EXPERIENCIA!</h3>
								<p className="white-text">
									Creemos que el sistema de reviews forja los cimientos de la web y por eso tu review ayuda a
									la comunidad.
								</p>
								<a className="button red-bg white-text">Comenzar!</a>
							</div>
							<div className="image blue">
								<Img fluid={images.contact.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						<article className="box-review ">
							<div className="wrapper box">
								<h3 className="prequel-font white-text">¿ALQUILASTE LA TABLA DE RODRIGO?</h3>
								<ul className="yes-no">
									<li>
										<a href="" className="button white-bg black-text-01">
											No
										</a>
									</li>
									<li>
										<a href="" className="button white-bg black-text-01">
											Si
										</a>
									</li>
								</ul>
							</div>
							<div className="image red">
								<Img fluid={images.boardPost2.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						<article className="box-review ">
							<div className="wrapper box">
								<h3 className="prequel-font white-text">¿QUE OCURRIO?</h3>
								<ul className="options">
									<li>
										<a href="" className="button white-bg prequel-font black-text-01">
											EL RENTER NO ME RESPONDIO
										</a>
									</li>
									<li>
										<a href="" className="button white-bg prequel-font black-text-01">
											EL RENTER CANCELO
										</a>
									</li>
									<li>
										<a href="" className="button white-bg prequel-font black-text-01">
											EL RENTER NO TENIA DISPONIBILIDAD
										</a>
									</li>
									<li>
										<a href="" className="button white-bg prequel-font black-text-01">
											OTROS
										</a>
									</li>
								</ul>
							</div>
							<div className="image red">
								<Img fluid={images.boardPost2.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						{/* Este es el gracias si el usuario eligió la respuesta NO */}
						<article className="box-review ">
							<div className="wrapper box">
								<h3 className="prequel-font white-text">MUCHisimaS GRACIAS!</h3>
								<p className="white-text">
									Lamentamos que no hayas podido alquilar pero te animamos a que vuelvas a intentarlo, tenemos
									una comunidad increíble de surfistas deseando conocerte! &#128521;
									{/* acá arriba se ve el código del emoji/emoticon de la personita guiñando el ojo
									decime si es mejor esta forma de poner emojis/emoticons o sino asi directamente : 😉 */}
								</p>
								<a className="button red-bg white-text">Ir a la home</a>
							</div>
							<div className="image blue">
								<Img fluid={images.contact.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						{/* Este es el gracias si el usuario eligió la respuesta NO */}
						<article className="box-review ">
							<div className="wrapper box">
								<h3 className="prequel-font white-text">MUCHisimaS GRACIAS!</h3>
								<p className="white-text">
									Creemos que el sistema de reviews forja los cimientos de la web y por eso tu review ayuda a
									la comunidad a crecer. Gracias de &#10084;&#65039;.
									{/* acá arriba se ve el código del corazón + el color, 
									está separado, decime si es mejor esta forma 
									de poner emojis/emoticons o sino asi directamente : ❤️ */}
								</p>
								<a className="button red-bg white-text">Ir a la home</a>
							</div>
							<div className="image blue">
								<Img fluid={images.contact.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						<article className="box-review ">
							<div className="wrapper box">
								<ul className="steps-bullets">
									<li>
										<span className="white-text active"></span>
									</li>
									<li>
										<span className="white-text"></span>
									</li>
									<li>
										<span className="white-text"></span>
									</li>
									<li>
										<span className="white-text"></span>
									</li>
								</ul>
								<h3 className="prequel-font white-text">¿como valoras la comunicacion con el renter?</h3>
								<ul className="buttons">
									<li>
										<a className="button white-bg black-text-01 next" href="">
											Siguiente
										</a>
									</li>
									<li>
										<a className="button white-text back" href="">
											Atrás
										</a>
									</li>
								</ul>
							</div>
							<div className="image red">
								<Img fluid={images.boardPost2.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						<article className="box-review ">
							<div className="wrapper box">
								<ul className="steps-bullets">
									<li>
										<span className="white-text seen"></span>
									</li>
									<li>
										<span className="white-text active"></span>
									</li>
									<li>
										<span className="white-text"></span>
									</li>
									<li>
										<span className="white-text"></span>
									</li>
								</ul>
								<h3 className="prequel-font white-text">¿Y SU PUNTUALIDAD?</h3>
								<ul className="buttons">
									<li>
										<a className="button white-bg black-text-01 next" href="">
											Siguiente
										</a>
									</li>
									<li>
										<a className="button white-text back" href="">
											Atrás
										</a>
									</li>
								</ul>
							</div>
							<div className="image red">
								<Img fluid={images.boardPost2.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						<article className="box-review ">
							<div className="wrapper box">
								<ul className="steps-bullets">
									<li>
										<span className="white-text seen"></span>
									</li>
									<li>
										<span className="white-text seen"></span>
									</li>
									<li>
										<span className="white-text active"></span>
									</li>
									<li>
										<span className="white-text"></span>
									</li>
								</ul>
								<h3 className="prequel-font white-text">¿Y LA VERACIDAD DEL ANUNCIO?</h3>
								<ul className="buttons">
									<li>
										<a className="button white-bg black-text-01 next" href="">
											Siguiente
										</a>
									</li>
									<li>
										<a className="button white-text back" href="">
											Atrás
										</a>
									</li>
								</ul>
							</div>
							<div className="image red">
								<Img fluid={images.boardPost2.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						<article className="box-review">
							<div className="wrapper box">
								<ul className="steps-bullets">
									<li>
										<span className="white-text seen"></span>
									</li>
									<li>
										<span className="white-text seen"></span>
									</li>
									<li>
										<span className="white-text seen"></span>
									</li>
									<li>
										<span className="white-text active"></span>
									</li>
								</ul>
								<h3 className="prequel-font white-text">ESCRIBE SOBRE TU EXPERIENCIA</h3>
								<form>
									<fieldset>
										<label className="black-text-01" htmlFor="experience">
											<textarea
												className="white-text"
												placeholder="Trato con el renter, detalles de la tabla..."
												name="experience"
												id="experience"
											/>
										</label>
									</fieldset>
								</form>
								<ul className="buttons">
									<li>
										<a className="button white-bg black-text-01" href="">
											Finalizar
										</a>
									</li>
									<li>
										<a className="button white-text back" href="">
											Atrás
										</a>
									</li>
								</ul>
							</div>
							<div className="image red">
								<Img fluid={images.boardPost2.childImageSharp.fluid} alt={``} />
							</div>
						</article>

						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Puntaje);
